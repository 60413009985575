/* eslint-disable */
declare global {
  interface Window {
    __REACT_APP_BASE_URL__: string
    __REACT_APP_BLOCKCHAIN_NETWORK_URL__: string
  }
}

const REACT_APP_BASE_URL: string = window.__REACT_APP_BASE_URL__ || ''

const REACT_APP_BLOCKCHAIN_NETWORK_URL: string = window.__REACT_APP_BLOCKCHAIN_NETWORK_URL__ || ''

export default { REACT_APP_BASE_URL, BLOCKCHAIN_NETWORK_URL: REACT_APP_BLOCKCHAIN_NETWORK_URL }
