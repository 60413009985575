/* eslint-disable */
/**
 * Represents the state structure for managing user-related data.
 */
export interface userState {
  status: 'idle' | 'loading' | 'failed' // Current state status
  error: string | null // Error message, if any
  organizations: IOrganizationsParsed[] // List of parsed organizations
  activeOrganization: IOrganizationsParsed // Active organization
  name: string | undefined | null // User's name
  profile: UserProfile // User's profile information
}

/**
 * Represents a user's profile information.
 */
export interface UserProfile {
  username: string | undefined | null // User's username
  name: string | undefined | null // User's name
  jobTitle: string | undefined | null // User's job title
  mfaEnabled: boolean // Multi-factor authentication status
  emailVerified: boolean // Email verification status
  email?: string // User's email address
}

/**
 * Represents the response structure for user information.
 */
export interface userInfoResponse {
  name: string | null // User's name
  jobTitle: string | null // User's job title
}

/**
 * Represents the response structure for login user information.
 */
export interface loginUserResponse {
  token: string // Authentication token
}

/**
 * Represents an organization's information.
 */
export interface IOrganization {
  id: string // Organization's ID
  name: string // Organization's name
}

/**
 * Represents a parsed organization's information.
 */
export interface IOrganizationsParsed {
  name: string // Organization's name
  value: string // Value associated with the organization
  defaultSecurity: boolean // Indicates if default security is enabled
  sensitive: boolean // Indicates if the organization is sensitive
  topSecret: boolean // Indicates if the organization is top secret
  id: string
}

/**
 * Represents an organization's information with security details.
 */
export interface IOrganizations {
  name: string // Organization's name
  id: string // Organization's ID
  defaultSecurity: boolean // Indicates if default security is enabled
  sensitive: boolean // Indicates if the organization is sensitive
  topSecret: boolean // Indicates if the organization is top secret
}

/**
 * Represents the response structure for getting user profile information.
 */
export interface IGetProfileResponse {
  organizations: IOrganizations[] // List of organizations
  name: string | null // User's name
  username: string | null // User's username
}

/**
 * Represents JWT data extracted from a user's token.
 */
export interface userJWTData {
  exp: number // Token expiration time
  iss: string // Token issuer
  jti: string // Token ID
  org_roles: {
    [key: string]: string[] // Organization roles
  }
  roles: string // User roles
  sub: string // Token subject
  mfa: boolean // Multi-factor authentication status
}

// This enum defines different user roles within the application.
export enum EUserRole {
  ROLE_USER = 'ROLE_USER', // Regular user role with basic permissions.
  ROLE_ORG_ADMIN = 'ROLE_ORG_ADMIN', // Organization administrator role with higher permissions.
  ROLE_SUPER_ADMIN = 'ROLE_SUPER_ADMIN', // Super administrator role with the highest level of permissions.
}
