import { Box, Button, FormControl, FormHelperText, Grid, Typography } from '@mui/material'
import './LoginPage.scss'
import React, { useState } from 'react'
import DSMLogo from '../../../assets/images/DSMLogin.png'
import PasswordInput from '../../atoms/PasswordInput'
import { ILoginPage } from './types'
import TextInput from '../../atoms/TextInput'
import PoweredByBinarii from '../../atoms/PoweredByBinarii'

const LoginPage: React.FC<ILoginPage> = ({ loginSubmitFunction, navigateToForgotPassword }) => {
  const [usernameError, setUsernameError] = useState<boolean | null>(null)
  const [username, setUsername] = useState<string>('')

  const [passwordError, setPasswordError] = useState<boolean | null>(null)
  const [password, setPassword] = useState<string>('')
  const [loginError, setLoginError] = useState<string>('')

  const onUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(e.target.value)
  }

  const onPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value)
  }

  const onLoginFormSubmit = async (e: React.SyntheticEvent): Promise<void> => {
    e.preventDefault()
    setPasswordError(null)
    setLoginError('')
    setUsernameError(null)
    let hasErrors
    if (!username) {
      setUsernameError(true)
      hasErrors = true
    }
    if (!password) {
      setPasswordError(true)
      hasErrors = true
    }
    if (hasErrors) return

    await loginSubmitFunction(username, password)
  }

  // username and password are mandatory
  const isButtonDisabled = () => username.length === 0 || password.length === 0

  return (
    <Grid sx={{ height: '85vh', bgcolor: 'background.paper' }}>
      <Grid height="100%" container justifyContent="center" alignItems="center">
        <Grid
          item
          className="login-inner-container"
          sx={{ '&': { display: 'flex', flexDirection: 'column', alignItems: 'center' } }}
        >
          <Box className="login-box">
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Box
                sx={{
                  width: '12.8rem',
                  height: '4.4rem',
                }}
              >
                <img src={DSMLogo} alt="dsm logo" />
              </Box>
            </Box>
            <form className="login-form" onSubmit={onLoginFormSubmit}>
              <FormControl>
                <TextInput
                  variant="outlined"
                  value={username}
                  onChange={onUsernameChange}
                  label="Username"
                  type="text"
                  id="username-input"
                  aria-describedby="username-helper-text"
                  fullWidth
                  autoComplete="username"
                  error={!!usernameError}
                  errorMessage="Username cannot be empty"
                />
              </FormControl>
              <FormControl>
                <PasswordInput
                  password={password}
                  onPasswordChange={onPasswordChange}
                  variant="outlined"
                  sx={{ mt: 2 }}
                  label="Password"
                  fullWidth
                  aria-describedby="password-helper-text"
                  autoComplete="password"
                  id="password-input"
                />
                {passwordError && (
                  <FormHelperText id="password-helper-text" error sx={{ fontSize: 12 }}>
                    Password cannot be empty
                  </FormHelperText>
                )}
              </FormControl>

              <Button
                size="large"
                variant="contained"
                color="purpleTheme"
                sx={{
                  mt: '1.6rem',
                  display: 'flex',
                  justifyContent: 'center',
                  borderRadius: '.5rem',
                }}
                type="submit"
                disabled={isButtonDisabled()}
              >
                Log in
              </Button>
              {loginError && (
                <Typography color="error.main" textAlign="center" sx={{ mt: 1 }}>
                  {loginError}
                </Typography>
              )}
            </form>
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
              <Button
                onClick={() => {
                  navigateToForgotPassword()
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: '1.5rem',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    lineHeight: '2.4rem',
                    textTransform: 'capitalize',
                    color: '#546473',
                  }}
                >
                  Forgot password?
                </Typography>
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Grid sx={{ height: '15vh', bgcolor: 'background.paper' }}>
        <PoweredByBinarii />
      </Grid>
    </Grid>
  )
}

export default LoginPage
